import React from "react";
import styles from "./EnergyBarChartPage.module.scss";

import Logo from "../../components/Logo/Logo";
import BarChartGame from "./BarChartGame/BarChartGame";

import MetaTags from "react-meta-tags";
import { ReactTitle } from "react-meta-tags";

const EnergyBarChartPage = () => {
  return (
    <div className={styles.EnergyBarChartPage}>
      <ReactTitle title="Energy Bar Charts" />
      <MetaTags>
        <meta
          name="description"
          content="Use conservation of energy to unlock the physics behind some of the coolest videos on YouTube! An energy game created by physics teacher Matthew Blackman at Universe & More."
        />

        <meta
          name="keywords"
          content="energy bar charts, energy game, conservation of energy app, energy physics game, energy bar chart app"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@UniverseAndMore" />
        <meta name="twitter:title" content="Energy Bar Charts" />
        <meta
          name="twitter:description"
          content="Use energy bar charts to unlock the physics behind some of the coolest videos on YouTube! An energy game created by physics teacher Matthew Blackman at Universe & More."
        />
        <meta name="twitter:creator" content="@UniverseAndMore" />

        <meta
          name="twitter:image:src"
          content="https://www.universeandmore.com/social-content/cards/energy.png"
        />

        <meta property="og:title" content="Energy Bar Charts" />
        <meta
          property="og:url"
          content="https://www.universeandmore.com/energy"
        />
        <meta
          property="og:image"
          content="https://www.universeandmore.com/social-content/cards/energy.png"
        />
        <meta
          property="og:description"
          content="Educational physics games and learning resources designed to build your intuition for physics concepts. Learn about kinematics, circuits, and forces with immersive games based on lessons from award-winning teacher Matthew Blackman. Our mission is to make physics accessible and fun for students and teachers across the universe...and more!"
        />
        <meta property="og:site_name" content="Energy Bar Charts" />
        <meta property="og:type" content="application/javascript" />
      </MetaTags>
      <Logo isLink />
      <div className={styles.Heading}>Energy Bar Charts</div>
      <div className={styles.BarChartGame}>
        <BarChartGame />
      </div>
      <p className={styles.Credits}>
        Application created by Matthew Blackman &copy;{" "}
        {new Date().getFullYear()}
        <br></br>Energy bar chart approach based on{" "}
        <em>College Physics: Explore and Apply</em> by Etkina, Planinsic and Van
        Heuvelen.
      </p>
      {/* )} */}
    </div>
  );
};

export default EnergyBarChartPage;
