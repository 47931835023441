import React, { useState } from "react";
import styles from "./EnergyBarChart.module.scss";
import EnergyBar from "./EnergyBar";
import WorkButton from "./WorkButton";
import EnergyButton from "./EnergyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const maxBarHeightRatio = 0.39;

const EnergyBarChart = (props) => {
  // const [width, height] = useWindowSize();
  const [chartHeight, setChartHeight] = useState(360);
  const [baselineY, setBaselineY] = useState(0.45);
  const [barWidthOuter, setBarWidthOuter] = useState(
    window.innerWidth > 800 ? "8rem" : "6rem"
  );
  const [workBarWidthOuter, setWorkBarWidthOuter] = useState(
    window.innerWidth > 800 ? "10rem" : "8rem"
  );
  const [barWidthInner, setBarWidthInner] = useState("70%");
  const bgLeftClasses = [styles.BarChartBGLeft];
  const bgRightClasses = [styles.BarChartBGRight];

  const workSectionClasses = [styles.WorkSection];

  if (props.instant1Highlighted) {
    bgLeftClasses.push(styles.BarChartBGHighlighted);
  }
  if (props.instant2Highlighted) {
    bgRightClasses.push(styles.BarChartBGHighlighted);
  }

  if (props.workShowing) {
    workSectionClasses.push(styles.WorkSectionShowing);
  }

  const energyBar = (state, type) => {
    return (
      <EnergyBar
        type={type}
        showing={true}
        widthOuter={barWidthOuter}
        widthInner={barWidthInner}
        height={chartHeight}
        maxBarHeightRatio={maxBarHeightRatio}
        baselineY={baselineY}
        unitScale={props.unitScale}
        showEnergyUnits={props.showEnergyUnits}
        updateNumSegments={(num) => props.updateNumSegments(state, type, num)}
      ></EnergyBar>
    );
  };

  return (
    <div className={styles.EnergyBarChartContainer}>
      <div className={styles.EnergyBarChart}>
        <div className={bgLeftClasses.join(" ")}>
          <div className={styles.TopHeading}>Initial</div>
          {!props.hasAnyBarsLeft && !props.hasAddedBar ? (
            <React.Fragment>
              <div className={styles.StarterMessage}>
                {props.energyButtonOpen
                  ? "Choose a form of energy"
                  : "Add an energy bar"}
              </div>
            </React.Fragment>
          ) : (
            <div className={styles.EnergyBars}>
              {props.hasBarLeft("KE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("initial", "KE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.KEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarLeft("KE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
              {props.hasBarLeft("GPE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("initial", "GPE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.GPEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarLeft("GPE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
              {props.hasBarLeft("EPE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("initial", "EPE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.EPEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarLeft("EPE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={workSectionClasses.join(" ")}
          style={{
            width: props.workShowing ? workBarWidthOuter : "1rem",
          }}
        >
          {props.workShowing && (
            <EnergyBar
              type={"W"}
              showing={props.workShowing}
              widthOuter={"100%"}
              widthInner={barWidthInner}
              height={chartHeight}
              maxBarHeightRatio={maxBarHeightRatio}
              baselineY={baselineY}
              unitScale={props.unitScale}
              showEnergyUnits={props.showEnergyUnits}
              updateNumSegments={(num) =>
                props.updateNumSegments(null, "W", num)
              }
            ></EnergyBar>
          )}
          <WorkButton
            clickHandler={props.workBtnHandler}
            workShowing={props.workShowing}
          />
        </div>
        <div className={bgRightClasses.join(" ")}>
          <div className={styles.TopHeading}>Final</div>
          {!props.hasAnyBarsRight && !props.hasAddedBar ? (
            <React.Fragment>
              <div className={styles.StarterMessage}>
                {props.energyButton2Open ? (
                  "Choose a form of energy"
                ) : (
                  <FontAwesomeIcon
                    className={styles.FaIconRight}
                    icon={faLongArrowAltRight}
                  />
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className={styles.EnergyBars}>
              {props.hasBarRight("KE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("final", "KE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.KEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarRight("KE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
              {props.hasBarRight("GPE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("final", "GPE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.GPEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarRight("GPE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
              {props.hasBarRight("EPE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("final", "EPE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.EPEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarRight("EPE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
              {props.hasBarRight("IE") && (
                <div className={styles.EnergyBarContainer}>
                  {energyBar("final", "IE")}
                  <div
                    className={`${styles.DeleteBtn} ${styles.IEDeleteBtn}`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      props.removeBarRight("IE");
                    }}
                  >
                    <hr className={styles.Minus}></hr>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <FontAwesomeIcon
          className={styles.FaIconTrash}
          icon={faTrash}
          onClick={props.resetBarChart}
        />
      </div>
      <EnergyButton
        showing={props.energyButtonShowing}
        isOpen={props.energyButtonOpen}
        setIsOpen={props.setEnergyButtonOpen}
        onClickHandler={() => props.setEnergyButtonOpen(true)}
        hasBar={props.hasBarRight}
        addBar={props.addBar}
      />
    </div>
  );
};

export default EnergyBarChart;
