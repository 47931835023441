import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import styles from "./EnergyBar.module.scss";

import { isMobile } from "react-device-detect";

const primaryRed = "#f3775b";
const primaryYellow = "#f7e25e";
const primaryBlue = "#41bdd7";
const primaryGreen = "#8ec099";

const maxSegments = 4;

const EnergyBar = (props) => {
  const boxRef = useRef(null);

  const [state, setState] = useState({
    isDragging: false,
    showFullHeading: false,
    mouseDeltaY: 0,
    numSegments: 0,
    showAlert: false,
    showAlertText: false,
  });

  useEffect(() => {
    if (state.isDragging) {
      setState({
        ...state,
        numSegments: Math.round(
          Math.max(
            -maxSegments,
            Math.min(
              (maxSegments *
                (props.height * (1 - props.baselineY) - state.mouseDeltaY)) /
                (props.height * props.maxBarHeightRatio),
              maxSegments
            )
          )
        ),
      });
    }
  }, [state.mouseDeltaY]);

  useEffect(() => {
    props.updateNumSegments(state.numSegments);
  }, [state.numSegments]);

  const handleMouseDown = useCallback((e) => {
    const clientY = e.clientY;
    setState((state) => ({
      ...state,
      isDragging: true,
      mouseDeltaY: clientY - boxRef.current.getBoundingClientRect().top,
    }));
  }, []);

  const handleMouseMove = useCallback((e) => {
    const clientY = e.clientY;
    setState((state) => ({
      ...state,
      mouseDeltaY: clientY - boxRef.current.getBoundingClientRect().top,
    }));
  }, []);

  const handleMouseUp = useCallback(() => {
    setState((state) => ({
      ...state,
      isDragging: false,
    }));
  }, []);

  const handleTouchDown = useCallback((e) => {
    const clientY = e.touches[0].clientY;
    setState((state) => ({
      ...state,
      isDragging: true,
      mouseDeltaY: clientY - boxRef.current.getBoundingClientRect().top,
    }));
  }, []);

  const handleTouchMove = useCallback((e) => {
    const clientY = e.touches[0].clientY;
    setState((state) => ({
      ...state,
      mouseDeltaY: clientY - boxRef.current.getBoundingClientRect().top,
    }));
  }, []);

  const handleTouchEnd = useCallback(() => {
    setState((state) => ({
      ...state,
      isDragging: false,
    }));
  }, []);

  useEffect(() => {
    if (state.isDragging) {
      if (isMobile) {
        window.addEventListener("touchmove", handleTouchMove);
        window.addEventListener("touchend", handleTouchEnd);
      } else {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
      }
    } else {
      if (isMobile) {
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("touchend", handleTouchEnd);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
    }
  }, [state.isDragging, handleMouseMove, handleMouseUp]);

  const stylesDragBox = useMemo(
    () => ({
      width: props.widthOuter,
      height: "100%",
      cursor: state.isDragging ? "-webkit-grabbing" : "-webkit-grab",
      position: "relative",
      zIndex: 5,
    }),
    [state.isDragging, state.mouseDeltaY]
  );

  const bgColor = useMemo(() => {
    if (props.type === "KE") {
      return primaryGreen;
    } else if (props.type === "GPE") {
      return primaryBlue;
    } else if (props.type === "EPE") {
      return primaryYellow;
    } else if (props.type === "W") {
      return "#9168ca";
    } else return primaryRed;
  }, [props]);

  const stylesEnergyBar = {
    width: props.widthInner,
    height: props.showing
      ? `${
          (Math.abs(state.numSegments) / maxSegments) *
          props.height *
          props.maxBarHeightRatio
        }px`
      : "0px",
    opacity: props.showing ? 1 : 0,
    transition: "opacity 0.2s ease",
    position: "absolute",
    bottom: `${props.baselineY * 100}%`,
    left: "50%",
    transformOrigin: "bottom",
    transform: `translate(-50%, ${0}px) scaleY(${
      state.mouseDeltaY > props.height * (1 - props.baselineY) ? -1 : 1
    })`,
    backgroundColor: `${bgColor}`,
    //   zIndex: state.isDragging ? 2 : 1,
    //   position: state.isDragging ? "absolute" : "relative",
  };

  const stylesBaseline = {
    top: `${(1 - props.baselineY) * 100}%`,
    borderColor: `${props.type === "W" ? "#aaa" : "#ddd"}`,
    width: props.type === "W" ? "86%" : "100%",
  };

  const headingFull = useMemo(() => {
    let text = "";
    if (props.type === "KE") {
      text = "Kinetic energy";
    } else if (props.type === "GPE") {
      text = "Gravitatonal potential energy";
    } else if (props.type === "EPE") {
      text = "Elastic potential energy";
    } else if (props.type === "W") {
      text = "Work";
    } else text = "Change in internal energy";

    return <div className={styles.HeadingFull}>{text}</div>;
  }, [props]);

  const heading = useMemo(() => {
    if (props.type === "KE") {
      return "KE";
    } else if (props.type === "GPE") {
      return "GPE";
    } else if (props.type === "EPE") {
      return "EPE";
    } else if (props.type === "W") {
      return "W";
    } else
      return (
        <p>
          &#916;E<sub>int</sub>
        </p>
      );
  }, [props]);

  const tickMarkLines = () => {
    const lines = [];

    for (let i = 1; i <= maxSegments; i++) {
      const p = (i * props.height * props.maxBarHeightRatio) / maxSegments;
      lines.push(p - 1);
      lines.push(-p);
    }

    return lines.map((val, i) => {
      return (
        <hr
          key={i}
          className={styles.TickLine}
          style={{
            top: `${(1 - props.baselineY) * 100}%`,
            transform: `translateY(${val}px)`,
            borderColor: `${props.type === "W" ? "#aaa" : "#dfdfdf"}`,
            width: props.type === "W" ? "86%" : "100%",
          }}
        ></hr>
      );
    });
  };

  const energyUnitLabelStyles = useCallback(() => {
    if (state.numSegments >= 0) {
      if ((props.type === "KE" || props.type === "EPE") && state.showAlert) {
        setState((state) => ({
          ...state,
          showAlert: false,
          showAlertText: false,
        }));
      }
      return {
        top: `${(1 - props.baselineY) * 100}%`,
        transform: "translateY(20%)",
        color: "#222",
      };
    } else if (
      (props.type === "KE" || props.type === "EPE") &&
      !state.showAlert
    ) {
      setState((state) => ({
        ...state,
        showAlert: true,
        showAlertText: false,
      }));
    }
    return {
      top: `${(1 - props.baselineY) * 100}%`,
      transform: "translateY(-120%)",
      color: props.type === "KE" || props.type === "EPE" ? primaryRed : "#222",
    };
  }, [state.numSegments]);

  const energyUnitLabel = useMemo(
    () => (
      <div className={styles.UnitLabel} style={energyUnitLabelStyles()}>
        {props.unitScale >= 1000
          ? (props.unitScale * state.numSegments) / 1000 + " kJ"
          : props.unitScale * state.numSegments + " J"}
      </div>
    ),
    [props.unitScale, state.numSegments]
  );

  const alertOverListener = (e) => {
    setState((state) => ({
      ...state,
      showAlertText: true,
    }));
    e.preventDefault();
  };

  const alertOutListener = (e) => {
    setState((state) => ({
      ...state,
      showAlertText: false,
    }));

    e.preventDefault();
  };

  const headingOverListener = (e) => {
    setState((state) => ({
      ...state,
      showFullHeading: true,
    }));
    e.preventDefault();
  };

  const headingOutListener = (e) => {
    setState((state) => ({
      ...state,
      showFullHeading: false,
    }));
    e.preventDefault();
  };

  const alertIcon = useMemo(
    () => (
      <FontAwesomeIcon
        className={styles.AlertIcon}
        icon={faExclamationTriangle}
        onMouseOver={alertOverListener}
        onMouseOut={alertOutListener}
      />
    ),
    [state.numSegments, state.showAlert]
  );

  const alertText = useMemo(() => {
    const energyName = props.type === "KE" ? "Kinetic" : "Elastic potential";
    return (
      <div className={styles.AlertText}>
        {energyName} energy cannot be negative.
      </div>
    );
  }, [state.showAlertText, state.showAlert]);

  return (
    <div
      style={stylesDragBox}
      className={styles.DragBox}
      onMouseDown={isMobile ? null : handleMouseDown}
      onTouchStart={isMobile ? handleTouchDown : null}
      ref={boxRef}
    >
      <div className={styles.BarContainer}>
        <div
          className={styles.BarHeading}
          onMouseOver={headingOverListener}
          onMouseOut={headingOutListener}
        >
          {state.showFullHeading && headingFull}
          {heading}
        </div>
        <div className={styles.Bar} style={stylesEnergyBar}></div>
        <hr className={styles.Baseline} style={stylesBaseline}></hr>
        {tickMarkLines()}
        {energyUnitLabel}
        {state.showAlert && alertIcon}
        {state.showAlert && state.showAlertText && alertText}
      </div>
    </div>
  );
};

export default EnergyBar;

// e.touches[0].clientY
