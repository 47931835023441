import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import styles from "./CheckModal.module.scss";

import ReactPlayer from "react-player/lazy";

import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CheckModal = (props) => {
  const [errors, setErrors] = useState([1]);

  const videoURL = "energy-videos/" + props.videoKey + ".mp4";

  const player = useRef(null);

  const isEnergyConserved = useCallback(() => {
    let totalInitial = 0;
    for (let i = 0; i < props.initialVals.length; i++) {
      totalInitial += props.initialVals[i].num;
    }

    let totalFinal = 0;
    for (let j = 0; j < props.finalVals.length; j++) {
      totalFinal += props.finalVals[j].num;
    }

    return totalInitial + props.workVal === totalFinal;
  }, [props.initialVals, props.finalVals, props.workVal]);

  const colorForCategory = (category) => {
    switch (category) {
      case "Initial state":
        return "#8ec099";
        break;

      case "Final state":
        return "#41bdd7";
        break;

      case "Work":
        return "#9168ca";
        break;

      case "Conservation of energy":
        return "#f3775b";
        break;

      default:
        break;
    }
  };

  const getValForStateAndType = (state, type) => {
    let val = 0;
    if (state === "initial") {
      for (let i = 0; i < props.initialVals.length; i++) {
        if (props.initialVals[i].type === type) {
          val = props.initialVals[i].num;
        }
      }
    } else if (state === "final") {
      for (let j = 0; j < props.finalVals.length; j++) {
        if (props.finalVals[j].type === type) {
          val = props.finalVals[j].num;
        }
      }
    }

    return val;
  };

  const doesValPassAuth = (val, auth) => {
    switch (auth) {
      case "nonzero":
        return val !== 0;

      case "zero":
        return val === 0;

      case "negative":
        return val < 0;

      case "positive":
        return val > 0;

      case "nonpositive":
        return val <= 0;

      case "nonnegative":
        return val >= 0;

      default:
        return false;
    }
  };

  const doValsPassAuth = (val1, val2, auth) => {
    switch (auth) {
      case "increases":
        return val1 < val2;

      case "decreases":
        return val1 > val2;

      case "constant":
        return val1 === val2;

      default:
        return false;
    }
  };

  const getCriteriaErrors = useCallback(() => {
    const criteriaErrors = [];

    for (let z = 0; z < props.initialVals.length; z++) {
      if (props.initialVals[z].num < 0) {
        if (props.initialVals[z].type === "KE") {
          criteriaErrors.push({
            category: "Initial state",
            hint: "Kinetic energy cannot be negative.",
            type: "KE",
          });
        } else if (props.initialVals[z].type === "EPE") {
          criteriaErrors.push({
            category: "Initial state",
            hint: "Elastic potential energy cannot be negative.",
            type: "EPE",
          });
        }
      }
    }

    for (let i = 0; i < props.criteria.initial.length; i++) {
      const auth = props.criteria.initial[i].criterion[0];
      const type = props.criteria.initial[i].criterion[1];
      const val = getValForStateAndType("initial", type);

      const passesCriterion = doesValPassAuth(val, auth);

      if (!passesCriterion) {
        criteriaErrors.push({
          category: "Initial state",
          hint: props.criteria.initial[i].hint,
          type: type,
        });
      }
    }

    for (let w = 0; w < props.finalVals.length; w++) {
      if (props.finalVals[w].num < 0) {
        if (props.finalVals[w].type === "KE") {
          criteriaErrors.push({
            category: "Final state",
            hint: "Kinetic energy cannot be negative.",
            type: "KE",
          });
        } else if (props.finalVals[w].type === "EPE") {
          criteriaErrors.push({
            category: "Final state",
            hint: "Elastic potential energy cannot be negative.",
            type: "EPE",
          });
        }
      }
    }

    for (let j = 0; j < props.criteria.final.length; j++) {
      const auth = props.criteria.final[j].criterion[0];
      const type = props.criteria.final[j].criterion[1];
      const val = getValForStateAndType("final", type);

      const passesCriterion = doesValPassAuth(val, auth);

      if (!passesCriterion) {
        criteriaErrors.push({
          category: "Final state",
          hint: props.criteria.final[j].hint,
          type: type,
        });
      }
    }

    for (let k = 0; k < props.criteria.work.length; k++) {
      const auth = props.criteria.work[k].criterion[0];
      const type = props.criteria.work[k].criterion[1];
      const val = props.workVal;

      const passesCriterion = doesValPassAuth(val, auth);

      if (!passesCriterion) {
        criteriaErrors.push({
          category: "Work",
          hint: props.criteria.work[k].hint,
          type: type,
        });
      }
    }

    for (let l = 0; l < props.criteria.comparison.length; l++) {
      const auth = props.criteria.comparison[l].criterion[0];
      const type = props.criteria.comparison[l].criterion[1];
      const val1 = getValForStateAndType("initial", type);
      const val2 = getValForStateAndType("final", type);

      const passesCriterion = doValsPassAuth(val1, val2, auth);

      if (!passesCriterion) {
        criteriaErrors.push({
          category: "Change",
          hint: props.criteria.comparison[l].hint,
          type: type,
        });
      }
    }

    return criteriaErrors;
  }, [props.initialVals, props.finalVals, props.workVal, props.criteria]);

  useEffect(() => {
    let newErrors = [];

    if (!isEnergyConserved()) {
      newErrors.push({
        category: "Conservation of energy",
        hint: "Your bar chart does not show conservation of energy.",
        type: "conservation",
      });
    }

    const criteriaErrors = getCriteriaErrors();

    if (criteriaErrors.length > 0) {
      newErrors = [...newErrors, ...criteriaErrors];
    }

    setErrors(newErrors);
  }, [props.initialVals, props.finalVals, props.workVal, props.criteria]);

  useEffect(() => {
    if (errors.length === 0) {
      props.levelBeatenListener();
    }
  }, [errors]);

  const openHelpLink = (helpLink) => {
    props.openHelpLink(helpLink);
  };

  const systemDisplay = useMemo(() => {
    return props.systemInfo.map((s, index) => {
      return (
        <div className={styles.SystemDisplay} key={index}>
          <div className={styles.IconContainer}>
            <FontAwesomeIcon
              className={styles.FaSquare}
              icon={faSquare}
            ></FontAwesomeIcon>
            {props.systemsCompleted.indexOf(index) !== -1 ? (
              props.systemNum == index ? (
                <FontAwesomeIcon
                  className={styles.FaCheckCurrent}
                  icon={faCheck}
                ></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon
                  className={styles.FaCheck}
                  icon={faCheck}
                ></FontAwesomeIcon>
              )
            ) : null}
          </div>
          <div
            className={styles.SystemName}
            onClick={() => {
              props.openNewSystem(index);
            }}
          >
            {s.system}
          </div>
        </div>
      );
    });
  }, [props.systemsCompleted]);

  const errorElements = useMemo(() => {
    if (errors.length === 0) {
      return (
        <div className={styles.WinContainer}>
          {isMobile && (
            <div className={styles.WinGraphicsContainer}>{systemDisplay}</div>
          )}
          <ReactPlayer
            ref={player}
            className={styles.Video}
            playing={true}
            playbackRate={1}
            controls={false}
            loop={true}
            volume={0}
            muted={true}
            playsinline={true}
            pip={false}
            url={videoURL}
            progressInterval={20}
            onProgress={() => {}}
          />
          {!isMobile && (
            <div className={styles.WinGraphicsContainer}>{systemDisplay}</div>
          )}
        </div>
      );
    } else
      return (
        <div className={styles.ErrorElements}>
          {errors.map((e, index) => {
            return (
              <div
                className={styles.ErrorElement}
                key={index}
                style={{ backgroundColor: `${colorForCategory(e.category)}` }}
              >
                <p className={styles.ErrorElementCategory}>
                  {e.category === "Change" ? "Change in" : e.category}{" "}
                  {e.type !== "conservation" &&
                  e.type !== "W" &&
                  e.category === "Change"
                    ? ` ${e.type}`
                    : e.type === "conservation" || e.type === "W"
                    ? ""
                    : ` - ${e.type}`}
                </p>
                {/* <FontAwesomeIcon
                  className={styles.FaInfoCircle}
                  icon={faInfoCircle}
                  onClick={() => {
                    openHelpLink(e.type);
                  }}
                /> */}
                <hr className={styles.ErrorDivider}></hr>
                <p className={styles.ErrorElementHint}>{e.hint}</p>
              </div>
            );
          })}
        </div>
      );
  }, [errors, props.systemsCompleted]);

  return (
    <div className={styles.CheckModalContainer}>
      <div
        className={styles.CheckModalBG}
        onClick={!isMobile ? props.closeCheckModal : null}
        onTouchStart={isMobile ? props.closeCheckModal : null}
      ></div>
      <div className={styles.CheckModal}>
        <div className={styles.CloseButton} onClick={props.closeCheckModal}>
          &times;
        </div>
        {errors.length === 0 &&
        props.totalSystems === props.systemsCompleted.length ? (
          <FontAwesomeIcon
            className={styles.FaStar}
            icon={faStar}
          ></FontAwesomeIcon>
        ) : null}
        <p className={styles.ErrorHeading}>
          {errors.length === 0
            ? props.totalSystems === props.systemsCompleted.length
              ? "All systems complete!"
              : "Correct!"
            : `${errors.length} error${errors.length === 1 ? "" : "s"} found`}
        </p>
        {errors.length > 0 &&
          (isMobile ? (
            <p className={styles.ErrorSubheading}>(Tap to show hints)</p>
          ) : (
            <p className={styles.ErrorSubheading}>(Hover to show hints)</p>
          ))}
        <hr className={styles.HeadingDivider}></hr>
        {errorElements}
      </div>
    </div>
  );
};

export default CheckModal;
