import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./VideoUI.module.scss";

import VideoSlider from "./VideoSlider";

import { isMobile } from "react-device-detect";

import ReactPlayer from "react-player/lazy";

const VideoUI = (props) => {
  const [playing, setPlaying] = useState(!isMobile);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);

  const instant1Percent = props.instant1;
  const instant2Percent = props.instant2;

  // const systemName = props.systemName;

  const videoURL = "energy-videos/" + props.videoKey + ".mp4";

  const player = useRef(null);

  const handleSeekMouseDown = (e) => {
    setPlaying(false);
    setSeeking(true);
  };

  const handleSeekChange = (val) => {
    setPlayed(parseFloat(val / 100));
    player.current.seekTo(val / 100);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    player.current.seekTo(e / 100, "fraction");
  };

  const handleProgress = (e) => {
    if (!seeking) {
      setPlayed(e.played);
    }
  };

  const seekToInstant = (instantPercent) => {
    setPlaying(false);
    handleSeekChange(instantPercent);
  };

  const playPauseBtnClasses = [];

  /////// Instant number labels

  // const instantLabelThreshold = ; //% margin to highlight instant label

  const instant1LabelClasses = isMobile
    ? [styles.Instant1LabelMobile]
    : [styles.Instant1Label];
  const instant2LabelClasses = isMobile
    ? [styles.Instant2LabelMobile]
    : [styles.Instant2Label];

  instant1LabelClasses.push("notranslate");
  instant2LabelClasses.push("notranslate");

  const instant1DisplayClasses = [styles.InstantDisplay];
  const instant2DisplayClasses = [styles.InstantDisplay];

  useEffect(() => {
    if (
      100 * played > instant1Percent - props.highlightThreshold &&
      100 * played < instant1Percent + props.highlightThreshold
    ) {
      if (!props.instant1Highlighted) {
        props.highlightInstant(1, true);
      }
    } else {
      if (props.instant1Highlighted) {
        props.highlightInstant(1, false);
      }
    }

    if (
      100 * played > instant2Percent - props.highlightThreshold &&
      100 * played < instant2Percent + props.highlightThreshold
    ) {
      if (!props.instant2Highlighted) {
        props.highlightInstant(2, true);
      }
    } else if (props.instant2Highlighted) {
      props.highlightInstant(2, false);
    }
  }, [played, instant1Percent, instant2Percent, props]);

  if (!isMobile) playPauseBtnClasses.push(styles.PlayButtonContainerDisappear);

  if (seeking) playPauseBtnClasses.push(styles.PlayButtonContainerInvisible);

  if (!playing) playPauseBtnClasses.push(styles.PlayButtonContainer);
  else playPauseBtnClasses.push(styles.PlayButtonContainerPaused);

  if (
    100 * played > instant1Percent - props.highlightThreshold &&
    100 * played < instant1Percent + props.highlightThreshold
  ) {
    instant1LabelClasses.push(styles.Instant1LabelHighlighted);
  }

  if (
    100 * played > instant2Percent - props.highlightThreshold &&
    100 * played < instant2Percent + props.highlightThreshold
  ) {
    instant2LabelClasses.push(styles.Instant2LabelHighlighted);
  }

  if (props.instant1Highlighted) {
    instant1DisplayClasses.push(styles.InstantDisplayShowing);
  } else instant1DisplayClasses.push(styles.InstantDisplayHiding);
  if (props.instant2Highlighted) {
    instant2DisplayClasses.push(styles.InstantDisplayShowing);
  } else instant2DisplayClasses.push(styles.InstantDisplayHiding);

  const bgColor = useCallback(
    (instant) => {
      if (instant === 1) {
        return played < instant1Percent / 100 ? "#51675a" : "#90bf9a";
      } else return played < instant2Percent / 100 ? "#51675a" : "#90bf9a";
    },
    [played]
  );

  return (
    <div className={styles.VideoUI}>
      <div
        className={styles.VideoContainer}
        onClick={() => {
          setPlaying((p) => {
            return !p;
          });
        }}
      >
        <div
          className={instant1DisplayClasses.join(" ")}
          style={{
            bottom: isMobile ? "10%" : `${props.instantLabelYShift}%`,
            transform: isMobile
              ? "translateX(-50%)"
              : `translateX(${-50 + props.instantLabelXShift}%)`,
          }}
        >
          Initial state
        </div>
        <div
          className={instant2DisplayClasses.join(" ")}
          style={{
            bottom: isMobile ? "10%" : `${props.instantLabelYShift}%`,
            transform: isMobile
              ? "translateX(-50%)"
              : `translateX(${-50 + props.instantLabelXShift}%)`,
          }}
        >
          Final state
        </div>
        <div className={playPauseBtnClasses.join(" ")}>
          <span
            className={`${styles.PlayButton} ${styles.PlayButtonBefore}`}
          ></span>
          <span
            className={`${styles.PlayButton} ${styles.PlayButtonAfter}`}
          ></span>
        </div>
        <ReactPlayer
          ref={player}
          className={styles.Video}
          playing={playing}
          playbackRate={props.playbackSpeed}
          playsinline={true}
          controls={false}
          loop={true}
          volume={0}
          muted={true}
          pip={false}
          url={videoURL}
          progressInterval={20}
          onProgress={handleProgress}
        />
        <p className={styles.LoadingMessage}>Loading...</p>
      </div>
      <div className={styles.SliderContainer}>
        <VideoSlider
          value={played * 100}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
      </div>
      <div className={styles.InstantLabels}>
        <div
          className={instant1LabelClasses.join(" ")}
          onClick={() => {
            seekToInstant(instant1Percent);
            props.highlightInstant(1, true);
            props.highlightInstant(2, false);
          }}
          style={{
            left: `${instant1Percent}%`,
            backgroundColor: `${bgColor(1)}`,
          }}
        >
          i
        </div>
        <div
          className={instant2LabelClasses.join(" ")}
          style={{
            left: `${instant2Percent}%`,
            backgroundColor: `${bgColor(2)}`,
          }}
          onClick={() => {
            seekToInstant(instant2Percent);
            props.highlightInstant(1, false);
            props.highlightInstant(2, true);
          }}
        >
          f
        </div>
      </div>
    </div>
  );
};

export default VideoUI;
