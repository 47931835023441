import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./BarChartGame.module.scss";

import VideoUI from "./VideoUI";
import EnergyBarChart from "./EnergyBarChart";
import MenuDropdown from "./MenuDropdown";
import CheckModal from "./Menus/CheckModal.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isMobile } from "react-device-detect";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import levelData from "./levelData.json";

const BarChartGame = () => {
  const [scenarioNum, setScenarioNum] = useState(0);
  const [systemNum, setSystemNum] = useState(0);
  const [barsLeft, setBarsLeft] = useState([]);
  const [barValuesLeft, setBarValuesLeft] = useState([]);
  const [barsRight, setBarsRight] = useState([]);
  const [barValuesRight, setBarValuesRight] = useState([]);
  const [hasAddedBar, setHasAddedBar] = useState(false);
  const [instant1Highlighted, setInstant1Highlighted] = useState(false);
  const [instant2Highlighted, setInstant2Highlighted] = useState(false);
  const [workShowing, setWorkShowing] = useState(false);
  const [workValue, setWorkValue] = useState(0);
  const [energyButtonShowing, setEnergyButtonShowing] = useState(true);
  const [energyButtonOpen, setEnergyButtonOpen] = useState(false);
  const [checkModalShowing, setCheckModalShowing] = useState(false);
  const [systemsCompleted, setSystemsCompleted] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [currentSystemsCompleted, setCurrentSystemsCompleted] = useState([]);
  const totalSystems = useMemo(
    () =>
      levelData.scenarios.map((p) => {
        return p.systems.length;
      }),
    [levelData]
  );

  const totalScenariosCompleted = useMemo(() => {
    let count = 0;
    for (let i = 0; i < systemsCompleted.length; i++) {
      if (systemsCompleted[i].length === totalSystems[i]) {
        count++;
      }
    }
    return count;
  }, [systemsCompleted]);

  ///LOADING LOCAL DATA
  useEffect(() => {
    const savedData = window.localStorage.getItem("systemsComplete");
    if (savedData) setSystemsCompleted(JSON.parse(savedData));
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "systemsComplete",
      JSON.stringify(systemsCompleted)
    );
  }, [systemsCompleted]);

  useEffect(() => {
    setCurrentSystemsCompleted(systemsCompleted[scenarioNum]);
  }, [systemsCompleted, scenarioNum]);

  useEffect(() => {
    resetBarChart();
  }, [scenarioNum, systemNum]);

  useEffect(() => {
    if (barsRight.length === 4) {
      setEnergyButtonShowing(false);
      setEnergyButtonOpen(false);
    } else setEnergyButtonShowing(true);
  }, [barsRight.length]);

  const levelInfo = useMemo(
    () => levelData.scenarios[scenarioNum],
    [levelData, scenarioNum]
  );

  const digit1Classes = [styles.Digit];
  const digit2Classes = [styles.Digit];

  if (instant1Highlighted) digit1Classes.push(styles.DigitHighlighted);
  else if (instant2Highlighted) digit2Classes.push(styles.DigitHighlighted);

  const highlightInstantListener = (instant, highlight) => {
    if (instant === 1) {
      setInstant1Highlighted(highlight);
    } else if (instant === 2) {
      setInstant2Highlighted(highlight);
    }
  };

  const workBtnHandler = () => {
    setWorkShowing((ws) => {
      if (ws) {
        setWorkValue(0);
      } else {
        setWorkValue(0);
      }
      return !ws;
    });
  };

  const addBar = (type) => {
    setBarsRight((p) => {
      if (p.indexOf(type) !== -1) {
        return p;
      } else {
        p.push(type);
        return [...p];
      }
    });
    setBarValuesRight((p) => {
      return [...p, { type: type, val: 0 }];
    });

    if (!hasAddedBar) {
      setHasAddedBar(true);
    }

    if (type === "IE") return;

    setBarsLeft((p) => {
      if (p.indexOf(type) !== -1) {
        return p;
      } else {
        p.push(type);
        return [...p];
      }
    });

    setBarValuesLeft((p) => {
      return [...p, { type: type, val: 0 }];
    });
  };

  const removeBar = (type) => {
    if (type === "IE") {
      if (barsLeft.length === 0 && barsRight.length === 1) {
        setHasAddedBar(false);
      }
    } else if (barsLeft.length === 1 && barsRight.length === 1) {
      setHasAddedBar(false);
    }

    setBarsRight((p) => {
      const index = p.indexOf(type);
      if (index < 0) return [...p];
      p.splice(index, 1);
      return [...p];
    });
    setBarValuesRight((p) => {
      return p.filter((e) => {
        return e.type !== type;
      });
    });

    if (type === "IE") {
      return;
    }

    setBarsLeft((p) => {
      const index = p.indexOf(type);
      if (index === -1) return [...p];
      p.splice(index, 1);
      return [...p];
    });
    setBarValuesLeft((p) => {
      return p.filter((e) => {
        return e.type !== type;
      });
    });
  };

  const hasAnyBarsLeft = useMemo(() => {
    return barsLeft.length > 0;
  }, [barsLeft]);

  const hasAnyBarsRight = useMemo(() => {
    return barsRight.length > 0;
  }, [barsRight]);

  const hasBarLeft = (type) => {
    return barsLeft.indexOf(type) !== -1;
  };

  const hasBarRight = (type) => {
    return barsRight.indexOf(type) !== -1;
  };

  const scenarioDropdown = useMemo(
    () => (
      <MenuDropdown
        availableItems={levelData.scenarios.map((p) => {
          return p.scenario;
        })}
        uiIndex={1}
        selectedItem={scenarioNum}
        systemsBeaten={systemsCompleted}
        totalSystems={totalSystems}
        clickListener={(e) =>
          setScenarioNum((p) => {
            if (p !== e) {
              setSystemNum(0);
            }
            return e;
          })
        }
      >
        Event
      </MenuDropdown>
    ),
    [scenarioNum, systemNum, systemsCompleted]
  );

  const scenarioDescription = useMemo(
    () => levelInfo.description,
    [scenarioNum]
  );

  const systemDropdown = useMemo(
    () => (
      <MenuDropdown
        availableItems={levelInfo.systems.map((p) => {
          return p.system;
        })}
        uiIndex={2}
        systemsBeaten={currentSystemsCompleted}
        clickListener={(e) => setSystemNum(e)}
      >
        System
      </MenuDropdown>
    ),
    [scenarioNum, systemNum, systemsCompleted, currentSystemsCompleted]
  );

  const systemDescription = levelInfo.systems[systemNum].system;

  const unitScale = useMemo(
    () => levelData.scenarios[scenarioNum].unitScale,
    [scenarioNum]
  );

  const resetBarChart = () => {
    setBarsLeft([]);
    setBarsRight([]);
    setWorkShowing(false);

    setBarValuesLeft([]);
    setBarValuesRight([]);
    setWorkValue(0);

    setHasAddedBar(false);
  };

  const openCheckModal = () => {
    setCheckModalShowing(true);
  };

  const closeCheckModal = () => {
    setCheckModalShowing(false);
  };

  const openHelpLink = (helpLink) => {
    if (checkModalShowing) {
      setCheckModalShowing(false);
    }
  };

  const openNewSystem = (index) => {
    setSystemNum(index);
    setCheckModalShowing(false);
  };

  const levelBeatenListener = useCallback(() => {
    setSystemsCompleted((p) => {
      return p.map((s, index) => {
        if (index === parseInt(scenarioNum)) {
          if (s.indexOf(parseInt(systemNum)) === -1) {
            return [...s, parseInt(systemNum)];
          } else return s;
        } else return s;
      });
    });
  }, [scenarioNum, systemNum, systemsCompleted]);

  const checkModal = useMemo(
    () => (
      <CheckModal
        closeCheckModal={closeCheckModal}
        initialVals={barValuesLeft}
        workVal={workValue}
        finalVals={barValuesRight}
        criteria={levelData.scenarios[scenarioNum].systems[systemNum].criteria}
        openHelpLink={openHelpLink}
        videoKey={levelInfo.videoKey}
        systemInfo={levelInfo.systems}
        scenarioNum={scenarioNum}
        systemNum={systemNum}
        openNewSystem={openNewSystem}
        systemsCompleted={currentSystemsCompleted}
        totalSystems={totalSystems[scenarioNum]}
        levelBeatenListener={levelBeatenListener}
      ></CheckModal>
    ),
    [checkModalShowing, systemsCompleted, currentSystemsCompleted]
  );

  const updateNumSegments = (state, type, num) => {
    if (type === "W") {
      setWorkValue(num);
    } else if (state === "initial") {
      setBarValuesLeft((p) => {
        return p.map((energy) => {
          if (energy.type === type) {
            return { type, num };
          } else return energy;
        });
      });
    } else {
      setBarValuesRight((p) => {
        return p.map((energy) => {
          if (energy.type === type) {
            return { type, num };
          } else return energy;
        });
      });
    }
  };

  const checkButton = (
    <button
      className={styles.CheckButton}
      onClick={isMobile ? null : openCheckModal}
      onTouchStart={isMobile ? openCheckModal : null}
      // disabled={
      //   barsLeft.length === 0 && barsRight.length === 0 && !workShowing
      // }
    >
      Check
    </button>
  );

  const progressDisplay1 = useMemo(() => {
    return (
      <div className={styles.ProgressDisplay1}>
        <FontAwesomeIcon className={styles.FaStar} icon={faStar} />
        {totalScenariosCompleted} / {levelData.scenarios.length}
      </div>
    );
  }, [systemsCompleted]);

  const progressDisplay2 = useMemo(() => {
    return (
      <div className={styles.ProgressDisplay2}>
        <FontAwesomeIcon
          className={styles.FaCheck}
          icon={faCheckSquare}
        ></FontAwesomeIcon>
        {currentSystemsCompleted.length} / {levelInfo.systems.length}
      </div>
    );
  }, [currentSystemsCompleted, scenarioNum]);

  return (
    <div className={styles.BarChartGame}>
      {checkModalShowing && checkModal}
      <div className={styles.InfoMessage}>
        Select an <span className={styles.ScenarioText}>event</span> and a{" "}
        <span className={styles.SystemText}>system</span> to analyze. Watch the
        video and create an energy bar chart for the initial and final states of
        the system. Click 'Check' when ready.
      </div>
      <div className={styles.FiltersTop}>
        <div className={styles.Scenario}>
          <div className={styles.ScenarioTop}>
            {scenarioDropdown}
            {progressDisplay1}
          </div>
          <p className={styles.ScenarioDescription}>{scenarioDescription}</p>
        </div>
        <div className={styles.System}>
          <div className={styles.SystemTop}>
            {systemDropdown}
            {progressDisplay2}
          </div>
          <p className={styles.SystemDescription}>{systemDescription}</p>
        </div>
      </div>
      <div className={styles.MainContent}>
        <div className={styles.VideoWrapper}>
          <div className={styles.Video}>
            <VideoUI
              className={styles.VideoUI}
              videoKey={levelInfo.videoKey}
              playbackSpeed={levelInfo.playbackSpeed}
              instant1={levelInfo.initial}
              instant2={levelInfo.final}
              systemName={levelInfo.systems[systemNum].system}
              instant1Highlighted={instant1Highlighted}
              instant2Highlighted={instant2Highlighted}
              highlightThreshold={
                levelData.scenarios[scenarioNum].highlightThreshold
              }
              highlightInstant={(instant, highlighted) =>
                highlightInstantListener(instant, highlighted)
              }
              instantLabelYShift={
                levelData.scenarios[scenarioNum].instantDisplayY
              }
              instantLabelXShift={
                levelData.scenarios[scenarioNum].instantDisplayX
              }
            />
          </div>
          <p className={styles.SystemName}>
            System: {levelInfo.systems[systemNum].system}
          </p>
        </div>
        {checkButton}
        <div className={styles.BCWrapper}>
          <EnergyBarChart
            instant1Highlighted={instant1Highlighted}
            instant2Highlighted={instant2Highlighted}
            workShowing={workShowing}
            hasBarLeft={hasBarLeft}
            hasAnyBarsLeft={hasAnyBarsLeft}
            addBar={addBar}
            removeBarLeft={removeBar}
            hasBarRight={hasBarRight}
            hasAnyBarsRight={hasAnyBarsRight}
            removeBarRight={removeBar}
            hasAddedBar={hasAddedBar}
            workBtnHandler={workBtnHandler}
            unitScale={unitScale}
            resetBarChart={resetBarChart}
            updateNumSegments={updateNumSegments}
            energyButtonShowing={energyButtonShowing}
            energyButtonOpen={energyButtonOpen}
            setEnergyButtonOpen={setEnergyButtonOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default BarChartGame;
