import React, { useState, useMemo } from "react";
import styles from "./MenuDropdown.module.scss";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const useButtonStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "5rem",
    color: "#f9f9f9",
    backgroundColor: "#f3775b",
    height: 48,
    minHeight: 60,
    fontSize: "3rem",
    paddingTop: "35px",
    paddingBottom: "35px",
    paddingLeft: "40px",
    paddingRight: "40px",
    textTransform: "none",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      height: 48,
      minHeight: 48,
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontSize: "2rem",
    },
  },
  root2: {
    borderRadius: "5rem",
    color: "#f9f9f9",
    backgroundColor: "#41bdd7",
    height: 48,
    minHeight: 60,
    fontSize: "3rem",
    paddingTop: "35px",
    paddingBottom: "35px",
    paddingLeft: "40px",
    paddingRight: "40px",
    textTransform: "none",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      height: 48,
      minHeight: 48,
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontSize: "2rem",
    },
  },
}));

const useItemStyles1 = makeStyles((theme) => ({
  root: {
    fontSize: "2.5rem",
    fontFamily: "Montserrat",
    textTransform: "none",
    backgroundColor: "#f3775b",
    color: "#f9f9f9",
    "&:hover": {
      color: "#f3775b",
      backgroundColor: "#f9f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
  },
}));

const useItemStyles2 = makeStyles((theme) => ({
  root: {
    fontSize: "2.5rem",
    fontFamily: "Montserrat",
    textTransform: "none",
    backgroundColor: "#41bdd7",
    color: "#f9f9f9",
    "&:hover": {
      color: "#41bdd7",
      backgroundColor: "#f9f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
  },
}));

const ITEM_HEIGHT = 70;

const MenuDropdown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const buttonClasses = useButtonStyles();
  const itemClasses1 = useItemStyles1();
  const itemClasses2 = useItemStyles2();

  const handleClick = (event) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (event) => {
    const { myValue } = event.currentTarget.dataset;
    if (myValue || myValue === 0) {
      props.clickListener(myValue);
    }
    handleClose();
  };

  const itemCompletionText = (i) => {
    return (
      " (" + props.systemsBeaten[i].length + "/" + props.totalSystems[i] + ")"
    );
  };

  const menuItems = useMemo(() => {
    return props.availableItems.map((item, i) => {
      const rootStyles =
        props.uiIndex === 1 ? itemClasses1.root : itemClasses2.root;

      return (
        <MenuItem
          key={item}
          data-my-value={i}
          classes={{
            root: rootStyles,
          }}
          onClick={handleItemClick}
        >
          <div className={styles.ItemContent}>
            {props.uiIndex === 2 ? (
              props.systemsBeaten.indexOf(i) !== -1 ? (
                <FontAwesomeIcon
                  className={styles.MenuCheck}
                  icon={faCheckSquare}
                />
              ) : (
                <FontAwesomeIcon className={styles.MenuCheck} icon={faSquare} />
              )
            ) : (
              props.systemsBeaten[i].length === props.totalSystems[i] && (
                <FontAwesomeIcon className={styles.FaStar} icon={faStar} />
              )
            )}
            {item}
            {props.uiIndex === 1 && itemCompletionText(i)}
          </div>
        </MenuItem>
      );
    });
  }, [props.systemsBeaten, props.availableItems]);

  const bgCol =
    props.uiIndex === 1
      ? "#f3775b"
      : props.uiIndex === 2
      ? "#41bdd7"
      : "#8ec099";

  const classes = [styles.MenuDropdown];

  return (
    <div className={classes.join(" ")}>
      <Button
        classes={
          props.uiIndex == 1
            ? {
                root: buttonClasses.root1,
              }
            : {
                root: buttonClasses.root2,
              }
        }
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.children}
        <FontAwesomeIcon className={styles.FaIcon} icon={faChevronDown} />
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{ disablePadding: true, onMouseLeave: handleClose }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleItemClick}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            backgroundColor: bgCol,
          },
        }}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default MenuDropdown;
